@config "../../tailwind.config.ts";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-rgb: 255, 255, 255;
  --link-color: #0f69ff;
}

@media (prefers-color-scheme: dark) {
  [data-mode="auto"]:root {
    --link-color: #0091e6;
  }
}

body {
  background: rgb(var(--background-rgb));
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--link-color);
}

article a:hover {
  /* Applying underline on hover for anchor links within article body only */
  text-decoration: underline;
}

article [data-jump-link-target] {
  scroll-margin-top: 150px;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .pause-animation {
    animation-play-state: paused;
  }
}

@layer utilities {
  .stretched-box::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@layer base {
  /* Overriding tailwind default (bolder) to just bold instead */
  article b,
  article strong {
    @apply font-bold;
  }
}

@layer base {
  .atf-gradient {
    background: linear-gradient(
      180deg,
      #e7e3ff 0%,
      #f0f3f5 5.5%,
      #f9fcff 15.5%,
      #fff 25.5%
    );
  }

  @media screen and (min-width: 768px) {
    .atf-gradient {
      background: linear-gradient(
        180deg,
        #e7e3ff 0%,
        #e0e4e9 10.5%,
        #f9fcff 25.5%,
        #fff 50.5%
      );
    }
  }

  @media screen and (min-width: 1024px) {
    .atf-gradient {
      background: linear-gradient(
        180deg,
        #e7e3ff 0%,
        #e0e4e9 18%,
        #f9fcff 60%,
        #fff 70%
      );
    }
  }

  @media screen and (min-width: 1280px) {
    .atf-gradient {
      background: linear-gradient(
        180deg,
        #e7e3ff 0%,
        #e0e4e9 25.5%,
        #f9fcff 86.5%,
        #fff 100%
      );
    }
  }
}

